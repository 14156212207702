<script setup lang="ts">
export interface BaseHeaderLinksProps {
  links: IHeaderLink[];
  leftOffset?: number;
  rightOffset?: number;
}

const props = withDefaults(defineProps<BaseHeaderLinksProps>(), {
  leftOffset: 0,
  rightOffset: 0,
});

const visibleLinks = ref<IHeaderLink[]>([]);
const invisibleLinks = ref<IHeaderLink[]>([]);

const resizeLinks = () => {
  let totalWidth = 0;
  const maxWidth = window.innerWidth - props.rightOffset - props.leftOffset - 100;

  visibleLinks.value = [];
  invisibleLinks.value = [];

  for (let i = 0; i < props.links.length; i++) {
    const link = document.createElement("span");
    link.style.visibility = "hidden";
    link.style.fontWeight = "500";
    document.body.appendChild(link);

    link.innerHTML = props.links[i].title;

    const linkWidth = Math.min(link.offsetWidth, 144) + 48;
    totalWidth += linkWidth;

    if (totalWidth <= maxWidth)
      visibleLinks.value.push(props.links[i]);
    else
      invisibleLinks.value.push(props.links[i]);
    document.body.removeChild(link);
  }
};
watchEffect(resizeLinks);
</script>

<template>
  <div class="flex items-center">
    <BaseHeaderVisibleLinks :links="visibleLinks" />
    <BaseHeaderInvisibleLinks class="mt-2 hidden lg:block" :links="invisibleLinks" />
  </div>
</template>
