<script setup lang="ts">
export interface HeaderUser {
  fullName: string;
}
export interface BaseHeaderBurgerMenuProps {
  limit?: number;
  links?: IHeaderLink[];
  withAuth?: boolean;
  withUser?: null | HeaderUser;
  withThemeSwitcher?: boolean;
}

const props = withDefaults(defineProps<BaseHeaderBurgerMenuProps>(), { limit: 8 });
const emit = defineEmits(["logout"]);

const { t } = useI18n();
const route = useRoute();
const isMenuExpanded = ref(false);
const appConfig = useRuntimeConfig().public;
const availableLocales = appConfig.APP_CUSTOMER.available_languages_list as ILocale[];
const isVisibleDropdownLocale = computed(() => availableLocales.length > 1);

const setMenuExpanded = (value: boolean) => {
  isMenuExpanded.value = value;
  document.documentElement.style.overflow = value ? "hidden" : "";
  document.body.style.overflow = value ? "hidden" : "";
};

const onBurgerMenuClick = () => setMenuExpanded(!isMenuExpanded.value);

watch(() => route.path, () => setMenuExpanded(false));
</script>

<template>
  <div class="baseHeaderBurgerMenu lg:hidden">
    <BaseButton name="baseHeaderBurgerMenu" title="Menu" type="icon" class="flex items-center" @click="onBurgerMenuClick">
      <div :class="{ active: isMenuExpanded }" class="baseHeaderBurgerMenu__btn">
        <div />
        <div class="mt-1" />
        <div class="mt-1" />
      </div>
    </BaseButton>

    <div class="main-container background-base baseHeaderBurgerMenu__content" :class="{ headerBurger__menu_open: isMenuExpanded }">
      <div v-if="props.links?.length">
        <div
          v-for="link in props.links"
          :key="link.id"
          class="my-4"
        >
          <BaseCollapse v-if="link.subLinks?.length" :title="link.title">
            <template #title>
              <BaseButton
                type="secondary"
                :name="link.title"
                :tag="link.href.includes('http') ? 'a' : 'nuxt-link'"
                :to="link.href.includes('http') ? {} : link.href"
                :href="link.href.includes('http') ? link.href : undefined"
                link
              >
                <span class="text-main text-button-bold">{{ link.id === 'following' ? t('labels.following') : link.title }}</span>
              </BaseButton>
            </template>
            <div class="grid grid-cols-2 gap-6">
              <BaseLink
                v-for="subLink in link.subLinks"
                :key="subLink.id"
                class="text-link"
                :title="subLink.title"
                :image-url="subLink.imageUrl"
                :href="subLink.href"
              />
            </div>
          </BaseCollapse>
          <BaseButton
            v-else
            type="secondary"
            :name="link.title"
            :tag="link.href.includes('http') ? 'a' : 'nuxt-link'"
            :to="link.href.includes('http') ? {} : link.href"
            :href="link.href.includes('http') ? link.href : undefined"
            link
          >
            <span class="text-button-bold text-main">{{ link.id === 'following' ? t('labels.following') : link.title }}</span>
          </BaseButton>
        </div>

        <div v-if="props.withUser" role="separator" class="my-8 hr" style="background: var(--base-header-line-color);" />
        <BaseHeaderUserLinksList v-if="props.withUser" class="w-full" @logout="emit('logout')" />

        <div role="separator" class="my-8 hr" style="background: var(--base-header-line-color);" />

        <div class="flex justify-between mb-[10rem]">
          <UserMenuSelectionLanguage v-if="isVisibleDropdownLocale" close-btn :available-locales="availableLocales" />
          <BaseThemeSwitcher v-if="props.withThemeSwitcher" has-labels />
        </div>
      </div>

      <BaseHeaderAuthButtons v-if="props.withAuth" class="background-main">
        <slot name="auth-buttons" />
      </BaseHeaderAuthButtons>
    </div>
  </div>
</template>

<style lang="scss">
//FIXME: styles
.baseHeaderBurgerMenu {
  @apply z-30;
  .baseHeaderBurgerMenu__btn {
    @apply w-5;
    & > div {
      @apply duration-300;
      height: 2px;
      border-radius: 4px;
      background-color: var(--base-text-color);
    }
    &.active div {
      @apply m-0;
      &:nth-child(1) {
        transform: translateY(2px) rotate(-45deg);
      }
      &:nth-child(2) {
        @apply opacity-0;
      }
      &:nth-child(3) {
        transform: translateY(-2px) rotate(45deg);
      }
    }
  }
  .baseHeaderBurgerMenu__content {
    @apply flex hidden;

    &.headerBurger__menu_open {
      @apply block absolute w-full top-20 left-0 overflow-auto flex flex-col justify-between;
      height: calc(100vh - 80px);
    }

    .baseHeaderBurgerMenu__locale {
      // TODO: Implement Drawer component for locale switcher instead of style override
      .headerDropdown__menu {
        @apply fixed bottom-0 w-full z-50 rtl:right-0;
        padding: 3vw;
        min-width: 50vw;
        top: unset;
        height: 65%;
        overflow-y: auto;
      }
      &.headerDropdown_open::before {
        @apply fixed z-20 inset-0 opacity-75;
        background-color: var(--base-background-color);
        content: '';
      }
    }
  }
}
</style>
