<script setup lang="ts">
import { THEME_COLOR } from "~/constants";

export interface BaseThemeSwitcherProps {
  hasLabels?: boolean;
}

const { t } = useI18n();
const props = defineProps<BaseThemeSwitcherProps>();
const appConfig = useRuntimeConfig().public;
const color = useColorMode();
function toggleDark() {
  if (!appConfig.APP_CUSTOMER.enable_white_theme) {
    color.preference = "dark";
    return;
  }

  color.preference = color.value === THEME_COLOR.DARK ? THEME_COLOR.LIGHT : THEME_COLOR.DARK;
}
</script>

<template>
  <div
    class="mode-toggle select-none"
    :class="{ isDark: color.value === THEME_COLOR.DARK }"
  >
    <h6 v-if="props.hasLabels" class="label-dark text-secondary">
      {{ t("labels.dark") }}
    </h6>
    <div class="toggle-switch" data-test-id="theme-toggle-button" @click="toggleDark()">
      <div class="toggle-switch__disc">
        <IconMoon v-if="color.value === THEME_COLOR.DARK" class="icon-dark" />
        <IconSun v-else class="icon-light text-[var(--base-icon-color)]" />
      </div>
    </div>
    <h6 v-if="props.hasLabels" class="label-light text-secondary">
      {{ t("labels.light") }}
    </h6>
  </div>
</template>

<style lang="scss">
.mode-toggle {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .label-dark {
    letter-spacing: -0.15px;
  }
  .label-light {
    letter-spacing: -0.031px;
  }

  .label-dark,
  .label-light {
    padding: 8px 16px;
    min-width: 32px;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
  }

  &.isDark {
    color: #b9bdc1;

    .toggle-switch {
      width: 48px;
      background: #2c374b;
      .toggle-switch__disc {
        background: #1c2535;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        .icon-dark {
          position: absolute;
          top: 4px;
          left: 4px;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}

.toggle-switch {
  margin: 0;
  padding: 0;
  width: 48px;
  height: 24px;
  border: none;
  outline: none;
  overflow: hidden;
  position: relative;
  border-radius: 14px;
  display: inline-block;
  background: #afb6c1;
  position: relative;

  .toggle-switch__disc {
    background: #ffff;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 3px;
    left: 27px;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    .icon-light {
      position: absolute;
      top: 4px;
      left: 4px;
      transition: all 0.2s ease-in-out;
    }
  }
}

.toggle-switch,
.toggle-switch:before,
.toggle-switch:after {
  transition: all 200ms ease-in-out;
}
</style>
