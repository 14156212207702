<script setup lang="ts">
// TODO:
export interface BaseLink {
  title: string;
  imageUrl?: string;
  href: string;
}
const props = defineProps<BaseLink>();
const color = useColorMode();
const isDark = computed(() => color.value === "dark");
</script>

<template>
  <BaseButton
    link
    type="secondary"
    :name="props.title"
    tag="nuxt-link"
    :to="props.href"
  >
    <div class="flex items-center gap-2">
      <BaseLogo
        :src="props.imageUrl"
        :class="{ border: !isDark }"
        with-initials
        :title="props.title"
        :no-background="!isDark"
        fit="contain"
        :icon-background="isDark ? '#2c374b' : '#ffffff'"
      />
      <p class="line-clamp-2 text-secondary">
        {{ props.title }}
      </p>
    </div>
  </BaseButton>
</template>
