<script setup lang="ts">
const { t } = useI18n();
</script>

<template>
  <div>
    <slot>
      <div class="flex items-center justify-center flex-row-reverse max-lg:px-4 max-lg:py-10 text-center lg:flex-row max-lg:mb-24">
        <AuthButton
          mode="sign-in"
          type="icon"
          :title="t('labels.sign_in')"
          class="mx-6 text-button-bold text-main w-full lg:w-auto lg:flex-shrink-0"
        />
        <AuthButton
          mode="sign-up"
          class="text-button-bold w-full lg:w-auto lg:flex-shrink-0"
          :title="t('labels.sign_up')"
        />
      </div>
    </slot>
  </div>
</template>
