<script setup lang="ts">
/* eslint-disable max-len */
export interface IconLocaleProps {
  fill?: string;
}
const props = withDefaults(defineProps<IconLocaleProps>(), { fill: "#A9B7D1" });
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.642 0c2.223.473 4.11 1.886 5.24 3.813h-2.95a10.457 10.457 0 0 0-.684-1.776C10.814 1.172 10.27.461 9.642 0zM6.357 0C5.729.46 5.185 1.172 4.75 2.038c-.267.531-.497 1.129-.684 1.775h-2.95C2.247 1.886 4.134.473 6.357 0zM8.39.274c.791.184 1.555.917 2.162 2.125.212.422.4.896.562 1.414H8.39V.274zm-.781 0v3.539H4.885c.161-.518.35-.992.562-1.414C6.053 1.19 6.817.458 7.609.274zm7.682 4.337a8.25 8.25 0 0 1 .708 2.99h-3.51a15.506 15.506 0 0 0-.355-2.99h3.157zm-3.963 0c.216.911.352 1.922.378 2.99H8.391v-2.99h2.937zm-3.72 0V7.6H4.294a14.63 14.63 0 0 1 .379-2.99h2.937zm-3.742 0c-.206.925-.33 1.932-.355 2.99H.001a8.25 8.25 0 0 1 .708-2.99h3.157zM16 8.399a8.25 8.25 0 0 1-.708 2.99h-3.158c.206-.924.33-1.932.355-2.99H16zm-4.293 0a14.567 14.567 0 0 1-.379 2.99H8.391V8.4h3.316zm-4.098 0v2.99H4.672a14.579 14.579 0 0 1-.379-2.99H7.61zm-4.098 0c.025 1.058.149 2.066.355 2.99H.708A8.25 8.25 0 0 1 0 8.4h3.511zm11.371 3.788c-1.13 1.929-3.02 3.34-5.245 3.813.63-.462 1.177-1.177 1.612-2.044.267-.53.497-1.125.684-1.769h2.95zm-3.774 0c-.16.514-.344.988-.555 1.408-.607 1.208-1.37 1.946-2.162 2.131v-3.539h2.717zm-3.499 0v3.539c-.791-.185-1.555-.923-2.161-2.131a9.402 9.402 0 0 1-.556-1.408h2.717zm-3.542 0c.188.644.418 1.238.684 1.77.436.866.983 1.58 1.613 2.043-2.226-.472-4.116-1.885-5.246-3.813h2.95z"
      :fill="props.fill"
      fill-rule="evenodd"
    />
  </svg>
</template>
