<template>
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor" fill-rule="evenodd">
      <rect width="7" height="7" rx="2" />
      <rect x="9" width="7" height="7" rx="2" />
      <rect x="9" y="9" width="7" height="7" rx="2" />
      <rect y="9" width="7" height="7" rx="2" />
    </g>
  </svg>
</template>
