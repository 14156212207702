<script setup lang="ts">
export interface BaseHeaderInvisibleLinksProps {
  links: IHeaderLink[];
}

const { t } = useI18n();
const route = useRoute();

const props = defineProps<BaseHeaderInvisibleLinksProps>();
const gridRows = computed(() => `grid-template-rows: repeat(${Math.min(props.links.length, 12)}, minmax(0, 1fr))`);

const popoverPlacement = computed(() => route.path.startsWith("/search") ? "bottom-end" : "bottom-start");
</script>

<template>
  <UMenu
    v-if="$props.links.length"
    :dropdown="{
      placement: popoverPlacement,
    }"
  >
    <template #trigger="{ toggle }">
      <BaseButton
        name="header links"
        type="icon"
        title="Menu"
        class="transform rotate-90"
        @click="toggle"
      >
        <IconAction />
      </BaseButton>
    </template>
    <template #default="{ hide }">
      <UList class="grid grid-flow-col" :style="gridRows">
        <UListItem
          v-for="link in props.links"
          :key="link.id"
          class="max-w-[250px]"
          size="md"
          :has-hover="false"
        >
          <BaseButton
            link
            full-width
            type="secondary"
            :tag="link.href.includes('http') ? 'a' : 'nuxt-link'"
            :to="link.href.includes('http') ? {} : link.href"
            :href="link.href.includes('http') ? link.href : undefined"
            :name="link.title"
            :title="link.title"
            class="w-full"
            @click.capture="hide"
          >
            <p class="line-clamp-1 text-base">
              {{ link.id === 'following' || link.id === 'archive' ? t(`labels.${link.id}`) : link.title }}
            </p>
          </BaseButton>
        </UListItem>
      </UList>
    </template>
  </UMenu>
</template>
