<script setup lang="ts">
export interface UserMenuSelectionLanguageProps {
  availableLocales: ILocale[];
  closeBtn?: boolean;
}

const { t, locale: currentLocale, setLocale } = useI18n();
const props = defineProps<UserMenuSelectionLanguageProps>();

function changeLocale(locale: string) {
  setLocale(locale);
}
</script>

<template>
  <UMenu>
    <template #trigger="{ toggle }">
      <BaseButton
        type="icon"
        name="header-locale-dropdown"
        title="Switch locale"
        @click.stop.prevent="toggle"
      >
        <div class="flex items-center justify-center gap-2">
          <IconHeaderLocale fill="var(--base-icon-color)" />
          <span class="text-[var(--base-secondary-text-color)] font-medium uppercase truncate">
            {{ currentLocale }}
          </span>
        </div>
      </BaseButton>
    </template>
    <template #default="{ hide }">
      <UList>
        <UListItem
          v-if="props.closeBtn"
          :on-click-close-menu="false"
          :has-hover="false"
        >
          <div class="flex w-full justify-between items-center text-secondary">
            <span>{{ t("locales.title") }}</span>
            <BaseButton
              tag="button"
              type="icon"
              name="close button"
              @click="hide"
            >
              <IconSearchClose class="relative z-10" />
            </BaseButton>
          </div>
        </UListItem>
        <UListItem
          v-for="locale in props.availableLocales"
          :key="locale"
          size="md"
        >
          <BaseButton
            type="icon"
            name="header-locale-dropdown-option"
            class="w-full text-link !flex justify-between"
            full-width
            @click="changeLocale(locale)"
          >
            <span class="text-main">{{ t(`locales.${locale}`) }}</span>
            <IconCheck v-if="locale === currentLocale" />
          </BaseButton>
        </UListItem>
      </UList>
    </template>
  </UMenu>
</template>
