<script setup lang="ts">
// import { useVModel } from "@vueuse/core";

export interface BaseCollapseProps {
  title: string;
  opened?: boolean;
}

const props = withDefaults(defineProps<BaseCollapseProps>(), {
  opened: false,
});

// const emit = defineEmits(["toggle", "update:opened"]);
// const isCollapseOpen = useVModel(props, "opened", emit);
const isCollapseOpen = ref(props.opened);
const toggle = () => isCollapseOpen.value = !isCollapseOpen.value;
</script>

<template>
  <div :class="{ active: isCollapseOpen }" class="collapse">
    <div class="collapse-header">
      <slot name="title">
        <div class="text-heading-3 text-main">
          {{ props.title }}
        </div>
      </slot>
      <BaseButton
        :aria-expanded="isCollapseOpen"
        :aria-controls="`base-collapse-${props.title}`"
        class="text-main"
        tag="button"
        type="icon"
        name="baseCollapseHeader"
        @click="toggle"
      >
        <IconChevronDown v-if="isCollapseOpen" :width="10" :height="10" class="relative z-10" />
        <IconChevronUp v-else :width="10" :height="10" class="relative z-10" />
      </BaseButton>
    </div>
    <div :id="`base-collapse-${props.title}`" :aria-label="props.title" role="region" class="collapse-body" :hidden="!isCollapseOpen">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.collapse {
  @apply relative visible;

  .collapse-header {
    @apply w-full relative flex justify-between items-center;
  }

  &.active {
    .collapse-body {
      @apply opacity-100 h-auto py-8 px-0 pointer-events-auto;
    }
  }

  .collapse-body {
    @apply opacity-0 h-0 pointer-events-none;
  }
}
</style>
